import { NODE_IDS } from './src/constants';
import api from './src/helpers/claraplayer';
import { BULB_MIXES,SHADE_MIXES } from './src/metadata';
import urlParams from './src/helpers/urlParams';
import { changeHandler } from './src/configuration';
import { Model } from './src/Model';
import { Controllor } from './src/Controllor';
import { View } from './src/View';
import { CAMERA_NAME, sendPdf, sendEmail } from './src/helpers/pdfGenerator';
import {leadTime} from './src/helpers/leadTimeGenerator';
import {description} from './src/helpers/descriptionGenerator';
import { claraExport, saveConfig } from './src/helpers/exporter';
import {getImages} from './src/helpers/getInspiration';
import {cloneFixture} from './src/helpers/modular';
import { onLoadingStep } from './src/helpers/callbacks';
import {generatePrice} from './src/helpers/priceGenerator';
import {generateWeight} from './src/helpers/weightGenerator';
import {hoveringOn} from './src/helpers/callbacks';
import {
  PENDANTS,
  updatePendantNodes,
  updateTopGripNode,
  updateRopeNode,
  updateCurvedRopeNode,
  updateCeilingHookNode,
  updateCageNode,
  updateShadeNode,
  updateShadeFitterNode,
  updateSocketNode,
  updateBottomGripNode,
  updateBulbNode,
  pendantAttach,
  updateExtrasNode,
  updateSocketStemNode,
} from './src/pendants';

const { initialConfig, pinned } = urlParams;
Object.keys(initialConfig).forEach(key => {
  Model[key] = initialConfig[key];
  //console.log(key + " is " + Model[key] );
});
onLoadingStep('Loading Viewer...');
api.sceneIO
  .fetchAndUse(NODE_IDS.BASE_SCENE, null, {
    waitForPublish: true,
    pinned: pinned,
  })
  .then(async () => {
    onLoadingStep('Initializing Viewer...');
    api.configuration.initConfigurator({
      el: document.getElementById('configRoot'),
      form: 'Main',
      configuration: initialConfig,
    });
    api.commands.setCommandOptions('snapshot', {
      camera: CAMERA_NAME,
      dataType: 'blob',
      width: 1024,
      height: 1024,
    });
    api.configuration.generateDescription = description;
    api.configuration.cloneFixture = (value1, value2, value3) => cloneFixture(value1,value2, value3);
    api.configuration.generatePrice = (value1, value2, value3) => generatePrice(value1,value2, value3);
    api.configuration.generateWeight = (value1, value2, value3) => generateWeight(value1,value2, value3);
    api.configuration.generateInspiration = values => getImages(values);
    api.configuration.generateLeadTime = leadTime;
    api.configuration.generatePDF = values => sendPdf(values);
    api.configuration.sendEmail = values => sendEmail(values);
    onLoadingStep('Preparing Data...');
    BULB_MIXES.initBulbMixPattern();
    SHADE_MIXES.initShadeMixPattern();
    Controllor.resetModel(initialConfig.Product); //Prepare data
    await Controllor.resetConfigurator(initialConfig.Product); //Update product

    View.update['Product']();
  });
api.on('loaded', () => {
  onLoadingStep('Rendering Product...');
  api.configuration.setConfiguration(Model);
  api.on('configurationChange', changeHandler);
  // const playerDiv = document.getElementById(
  //   api._store.get('player').toJS().playerElement.parentNode.id
  // );
  // let x, y;
  // playerDiv.addEventListener('touchmove', e => {
  //   let newX = e.touches[0].clientX;
  //   let newY = e.touches[0].clientY;
  //   if (Math.abs(newX - x) > 0) {
  //     e.preventDefault();
  //   }
  //   x = newX;
  //   y = newY;
  // });
  // playerDiv.addEventListener('touchstart', e => {
  //   x = e.touches[0].clientX;
  //   y = e.touches[0].clientY;
  //   // e.preventDefault();
  // });
  api.commands.setCommandOptions('orbit', {
    turnTable: true,
    mobileOnly: true,
    touchVerticalDefault: false,
  });
  const c_id = api.scene.find('Camera_Main');
  api.player.frameScene(undefined, {
    c_id,
    mode: 'geometry',
    padding: 0.1,
  });
  api.commands.runCommand('snapshot');

  api.configuration.generateFBX = claraExport;
  api.configuration.saveConfig = saveConfig;


});

['fullscreen', 'home', 'vrSettings'].map(api.player.hideTool);

var paths = api.scene.filter({type: 'PolyMesh', plug:'Properties', property: 'interactive'});
api.scene.setAll(paths, true);



 api.player.addTool({
  click: function(ev) {
    console.log(ev)
    var nodes = api.player.filterNodesFromPosition(ev);
    console.log(nodes);
    if(nodes.length){

  
        hoveringOn(api.scene.get({
        id:nodes[0],
        property: 'name',
      }),ev.clientX,ev.clientY);

  
   
    
    }
  },/*
  nodeenter: function(nodeId){
    console.log(nodeId);
    const name = api.scene.get({
      id: nodeId,
      property: 'name',
    });
    if(name.includes("Socket")){
      hoveringOn('Socket');
      console.log("WE GOT SOCKET");
    }
    else if (name.includes("Bulb")){
      hoveringOn('Bulb');
      console.log("WE GOT Bulb");
    }
    else if (name.includes("Rope")){
      hoveringOn('Cord');
      console.log("WE GOT Rope");
    }

},
nodeleave: function(nodeId){
  const name = api.scene.get({
    id: nodeId,
    property: 'name',
  });
  if(name.includes("Socket")){
    console.log("WE LOST SOCKET");
  }
  else if (name.includes("Bulb")){
    console.log("WE LOST Bulb");
  }
  else if (name.includes("Rope")){
    console.log("WE LOST Rope");
  }
},*/
});


/* import * as THREE from './node_modules/three/build/three.module.js';
import { ARButton } from './node_modules/three/examples/jsm/webxr/ARButton.js';
import { FBXLoader } from './node_modules/three/examples/jsm/loaders/FBXLoader.js';

let container;
			let camera, scene, renderer;
			let controller;

			let reticle;

			let hitTestSource = null;
			let hitTestSourceRequested = false;

			init();
			animate();

			function init() {

				container = document.createElement( 'div' );
				document.body.appendChild( container );

				scene = new THREE.Scene();

				camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 20 );

				const light = new THREE.HemisphereLight( 0xffffff, 0xbbbbff, 1 );
				light.position.set( 0.5, 1, 0.25 );
				scene.add( light );

				//

				renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
				renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setSize( window.innerWidth, window.innerHeight );
				renderer.xr.enabled = true;
				container.appendChild( renderer.domElement );

				//

				document.body.appendChild( ARButton.createButton( renderer, { requiredFeatures: [ 'hit-test' ] } ) );

				//

				const geometry = new THREE.CylinderGeometry( 0.1, 0.1, 0.2, 32 ).translate( 0, 0.1, 0 );

				function onSelect() {

					if ( reticle.visible ) {

						const material = new THREE.MeshPhongMaterial( { color: 0xffffff * Math.random() } );
						const mesh = new THREE.Mesh( geometry, material );
						mesh.position.setFromMatrixPosition( reticle.matrix );
						mesh.scale.y = Math.random() * 2 + 1;
						//scene.add( mesh );

            var loader = new FBXLoader();

            loader.load(
            // resource URL
            "cluster.fbx",
            
            // onLoad callback
            // Here the loaded data is assumed to be an object
            function ( obj) {
            // Add the loaded object to the scene
           
            obj.position.setFromMatrixPosition( reticle.matrix );
           // let boundingBox = new THREE.Box3().setFromObject(obj)
           // let size = boundingBox.getSize() // Returns Vector3
           // console.log(size);
            obj.translateY( -1 );
            scene.add( obj );
          
            },
            
            // onProgress callback
            function ( xhr ) {
            console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
            
            // onError callback
            function ( err ) {
            console.error( 'An error happened' );
            }
            );

					}

				}

				controller = renderer.xr.getController( 0 );
				controller.addEventListener( 'select', onSelect );
				scene.add( controller );

				reticle = new THREE.Mesh(
					new THREE.RingGeometry( 0.15, 0.2, 32 ).rotateX( - Math.PI / 2 ),
					new THREE.MeshBasicMaterial()
				);
				reticle.matrixAutoUpdate = false;
				reticle.visible = false;
				scene.add( reticle );

				//

				window.addEventListener( 'resize', onWindowResize );

			}

			function onWindowResize() {

				camera.aspect = window.innerWidth / window.innerHeight;
				camera.updateProjectionMatrix();

				renderer.setSize( window.innerWidth, window.innerHeight );

			}

			//

			function animate() {

				renderer.setAnimationLoop( render );

			}

			function render( timestamp, frame ) {

				if ( frame ) {

					const referenceSpace = renderer.xr.getReferenceSpace();
					const session = renderer.xr.getSession();

					if ( hitTestSourceRequested === false ) {

						session.requestReferenceSpace( 'viewer' ).then( function ( referenceSpace ) {

							session.requestHitTestSource( { space: referenceSpace } ).then( function ( source ) {

								hitTestSource = source;

							} );

						} );

						session.addEventListener( 'end', function () {

							hitTestSourceRequested = false;
							hitTestSource = null;

						} );

						hitTestSourceRequested = true;

					}

					if ( hitTestSource ) {

						const hitTestResults = frame.getHitTestResults( hitTestSource );

						if ( hitTestResults.length ) {

							const hit = hitTestResults[ 0 ];

							reticle.visible = true;
        
							reticle.matrix.fromArray( hit.getPose( referenceSpace ).transform.matrix );

						} else {

							reticle.visible = false;

						}

					}

				}

				renderer.render( scene, camera );

			}
 */


