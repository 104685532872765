import { Model } from './Model';
import api from './helpers/claraplayer';
import { BULB_MIXES, SHADE_MIXES } from './metadata';
import {
  NODE_IDS,
  SOCKET_TYPES,
  ATTRNAME_NUMBER_PENDANTS,
  Colors,
  ROPE_SPECIALS,
  ROPE_LENGTH,
  VECTORS,
  INDEXMAP,
  CAGE_TYPES,
  SHADE_SHAPES,
  BULB_SHAPES,
} from './constants';
import urlParams from './helpers/urlParams';
import {
  getRotation,
  setTranslation,
  setNodeVisibility,
  setNodeMaterial,
  setVisibility,
  setMaterialBaseColor,
  setRotation,
  setMaterialReflection,
} from './helpers/sceneHelpers';

const fetchedScenes = {};
const { isInternal, pinned } = urlParams;
const PENDANTS = [];
const PENDANT_MATS = [
  'Rope Colors_R',
  'Rope Colors_T',
  'Rope Fabrics',
  'Rope Chain',
  'Wire Chain 1 Inch Brass',
  'Wire Chain 1 Inch Black',
  'mat_ThickRope_Twisted_Inverse_MP',
  'M_Grip',
  'M_Hook',
  'M_Cage',
  'M_Shade',
  'M_ShadeA',
  'M_ShadeW',
  'M_Shade_Fitter',
  'M_Shade_FitterA',
  'M_Socket',
  'M_SocketA',
  'M_SocketBW',
  'M_MTSocket',
  'Copper Metal Half Half',
  'mat_Thick_Rope_Start-End',
  'M_ShadeFelt-Grey',
  'M_ShadeFelt-Khaki',
  'M_ShadeFelt-Inside',
  'M_CeilingWhite',
  'M_SocketMarble',
  'M_Stem',
  'M_StemBW',
  'M_SocketNickel',
  'M_SocketBrass',
  'M_SocketGraphite',
  'M_Ceiling PlateBW',
  'M_GripBlack',
  'M_ShadeBrass',
  'M_ShadeMatte',
  'M_ShadeWicker',
  'M_ShadeWickerPattern',
  'M_Grip Chain',
  'M_Shade Cover',
  'M_Rigid Stem',
];
class Node {
  constructor(name) {
    this.config = null;
    this.nodeMap = null;
    this.id = null;
  }
}
class Pendant {
  constructor(id, name, index) {
    this.name = name;
    this.index = index;
    this.nodes = {
      TopGrip: new Node(),
      CurvedRope: new Node(),
      CeilingHook: new Node(),
      Rope: new Node(),
      Cage: new Node(),
      Shade: new Node(),
      ShadeFitter: new Node(),
      Socket: new Node(),
      BottomGrip: new Node(),
      Bulb: new Node(),
      Extras: new Node(),
      SocketStem: new Node(),
    };
    this.ropeLength = 0;
    this.id = id;
  }

  async init() {
    const index = this.index;
    const acc = {};
    const handles = PENDANT_MATS.map(async mat => {
      if (isInternal) {
        const matName = mat + index;
        const matId = api.scene.find(matName);
        if (matId) acc[mat] = matId;
        else {
          const originMatId = api.scene.find(mat);
          const nodeMap = await api.scene.clone(originMatId);
          acc[mat] = nodeMap[originMatId];
          await api.scene.set(
            { id: nodeMap[originMatId], property: 'name' },
            matName
          );
        }
      } else {
        const matId = api.scene.find(mat);
        acc[mat] = matId;
      }
    });
    await Promise.all(handles);
    this.matLib = acc;
  }

  resetNode(nodeName, hardReset) {
    const nodeMap = this.nodes[nodeName].nodeMap
      ? Object.assign({}, this.nodes[nodeName].nodeMap)
      : null;
    this.nodes[nodeName].nodeMap = null;
    this.nodes[nodeName].id = null;
    if (hardReset) this.nodes[nodeName].config = null;
    if (!nodeMap) return Promise.resolve();
    else
      return Promise.all(
        Object.values(nodeMap).map(nodeId => {
          if (nodeId === this.id) return Promise.resolve();
          else return api.sceneGraph.deleteNode(nodeId);
        })
      );
  }
  async createNode(sceneId, nodeName, nullName) {
    if (!fetchedScenes[sceneId]) {
      await api.sceneIO.fetch(sceneId, null, {
        waitForPublish: true,
        pinned: pinned,
      });
    }
    const nodeMap = await api.scene.clone(
      {
        from: { id: api.scene.find({ from: sceneId, name: 'Objects' }) },
        type: ['PolyMesh', 'Null'],
      },
      { withNull: !this.id ? this.name : nullName + this.index }
    );
    //await new Promise(resolve => setTimeout(resolve, 5000));
    this.nodes[nodeName].nodeMap = nodeMap;
    const originId = api.scene.find({ from: sceneId, name: nullName });
    this.nodes[nodeName].id = nodeMap[originId];
    const nullId = nodeMap[`${nullName}${this.index}`];
    await api.scene.reparent(this.id, { from: nullId, shallow: true });
    await api.sceneGraph.deleteNode(nullId);
    // console.log('Clone successful for ', nodeName);
    return Promise.resolve();
  }

  resetNodes(hardReset = false) {
    return Promise.all(
      Object.keys(this.nodes).map(nodeName => {
        this.resetNode(nodeName, hardReset);
      })
    );
  }
}
//Create/delete nodes & assign material
const PRODUCT_NODES = [
  updateCurvedRopeNode,
  updateRopeNode,
  updateShadeNode,
  updateCageNode,
  updateBulbNode,
  updateSocketNode,
  updateCeilingHookNode,
  updateShadeFitterNode,
  updateBottomGripNode,
  updateTopGripNode,
  updateSocketStemNode,
];

async function updatePendantNodes() {

  const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
  api.scene.setAll(
    {
      from: api.scene.find('Wrap Ropes'),
      type: ['PolyMesh'],
      plug: 'Properties',
      property: 'visible',
    },
    false
  );
  api.scene.setAll(
    {
      from: api.scene.find('Grape_Rope_Base'),
      type: ['PolyMesh'],
      plug: 'Properties',
      property: 'visible',
    },
    false
  );
  if (PENDANTS.length > numOfPendants) {
    for (let i = numOfPendants; i < PENDANTS.length; i++) {
      PENDANTS[i].resetNodes(true);
    }
    return Promise.resolve();
  } else {
    // for (let i = 0; i < PENDANTS.length; i++) {}
    for (let i = PENDANTS.length; i < numOfPendants; i++) {
      const index = i;
      const pendantNullName = 'PendantNull' + index;
      let id = await api.scene.addNode({
        name: pendantNullName,
        parent: api.scene.find('Objects'),
        type: 'Null',
      });
      const pendant = new Pendant(id, pendantNullName, index);
      await pendant.init();
      PENDANTS.push(pendant);
    }
  }
  const promises = [];
  for (let i = 0; i < numOfPendants; i++) {
    const index = i;
    promises.push(
      Promise.all(
        PRODUCT_NODES.map((func, idx) => {
          return func(index);
        })
      )
    );
  }
  return Promise.all(promises).then(() => {

  });
}

function updateTopGripNode(index) {
  const cordType = isInternal
    ? Model[`Cord Type - ${index + 1}`]
    : Model['Cord Type'];
  let config, topGripName;
  if (cordType === 'Thick') {
    config = 'Thick';
    topGripName = 'Rope_tie';
  } else if (cordType === 'Chain'){
    topGripName = 'Chain_grip_top'
  } else {
    topGripName = 'Grip';
    if (
      Model['Product'].indexOf('Wood') !== -1 ||
      Model['Product'].indexOf('Wrap Chandelier') !== -1
    ) {
      config = 'Wood ' + Model['Wood Stain'];
    } else {
      config = 'Metal';
    }
  }
  if (PENDANTS[index].nodes['TopGrip'].config !== config) {
    // console.log('Inside top grip', index, config);
    //Type changed
    PENDANTS[index].nodes['TopGrip'].config = config;
    PENDANTS[index].resetNode('TopGrip');
    return PENDANTS[index].createNode(
      NODE_IDS[topGripName],
      'TopGrip',
      'Grips'
    );
  } else if (Model['Product'] == 'Flush Mount' || Model['Product'].indexOf('Wall Sconce') !== -1 ) {
    setNodeVisibility(
      api.scene.find({
        from: PENDANTS[index].nodes['TopGrip'].id,
        name: topGripName,
      }),
      false
    );
    return Promise.resolve();
  }
  else {
    setNodeVisibility(
      api.scene.find({
        from: PENDANTS[index].nodes['TopGrip'].id,
        name: topGripName,
      }),
      true
    );
    return Promise.resolve();
  }
}


async function updateExtrasNode() {

  if (PENDANTS[0].nodes['Extras'].config != Model['Extras'] || PENDANTS[0].nodes['Extras'].config ){

  PENDANTS[0].resetNode('Extras');
  let extra = 'None';
  if ( Model['Product'].indexOf('Flush') === -1  &&  Model['Product'].indexOf('Sconce') === -1 ){
    extra = Model['Extras'];
  }
if (extra != 'None') {
  await PENDANTS[0].createNode(
    NODE_IDS[extra],
    'Extras',
    extra
  );
}
else{
  await PENDANTS[0].createNode(
    NODE_IDS['Extras_None'],
    'Extras',
    extra
  );
}
  PENDANTS[0].nodes['Extras'].config = Model['Extras'];
  if(Model['Extras'] != 'None') {
    setNodeVisibility(PENDANTS[0].nodes['Extras'].id,false);
   }

}





}

async function updateRopeNode(index) {

if (Model['Product'].indexOf('Wall Sconce') !== -1 || Model['Product'] === 'Flush Mount'){
 const ropeName = `Straight Rope Round`;


PENDANTS[index].resetNode('Rope');
await PENDANTS[index].createNode(
  NODE_IDS[ropeName],
  'Rope',
  'Straight Rope'
);
console.log(ropeName);
console.log(PENDANTS[index].nodes['Rope'].id);
  setVisibility(
    api.scene.find(PENDANTS[index].nodes['Rope'].id),
    false,
    'all'
  );


  return Promise.resolve();

}


  const product = Model['Product'];
  const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
  let length = 0,
    unit = 'Inches';
  const cordType = isInternal
    ? Model[`Cord Type - ${index + 1}`]
    : Model['Cord Type'];
  if (product.includes('Cluster')) {
    length = isInternal
      ? Model[`Fixture Length - ${index + 1}`]
      : Model['Fixture Length'];
    if (product.includes('Grape')) {
      length -= 10;
    } else if (product.includes('Staggered')) {
      const delta = Math.round((length * 2) / 3 / numOfPendants / 3) * 3 || 3;
      length = length / 3 + delta * index;
    }
  } else if (product.includes('Single')) {
    length = isInternal
      ? Model[`Length(Feet) - ${index + 1}`]
      : Model['Length(Feet)'];
    unit = 'Feet';
  } else if (product.includes('Swag')) {
    length = isInternal ? Model[`Length - ${index + 1}`] : Model['Length'];
    const lengthArr = isNaN(length) ? length.match(/\d+/g) : [length];
    if (lengthArr.length === 1) {
      length = lengthArr[0] * 1;
    } else if (lengthArr.length === 2) {
      //Retrieve rope length from table
      const i =
        Model[ATTRNAME_NUMBER_PENDANTS] * 1 === 19 ? INDEXMAP[index] : index;
      length = ROPE_LENGTH['Swag'][lengthArr[0]][numOfPendants][i];
    }
    unit = 'Feet';
    length -= 4;
  } else if (product.indexOf('Wood') !== -1 || product.indexOf('Metal Chandelier') !== -1 || product.indexOf('Modular Chandelier') !== -1 ) {

    //Wood
    length = isInternal ? Model[`Length - ${index + 1}`] : Model['Length'];
    const size = product
      .split('-')[1]
      .trim()
      .replace(/["]+/g, '');
      //IMPORTANT this will change the length if coming from swag.  Causes issues when loading from url for some reason
    if (length == 'Staggered' ||  String(length).includes("Feet")){
      length = '20-35 Staggered';
    }
    if (length === '20-35 Staggered')
      length = ROPE_LENGTH['Wood'][size][numOfPendants][index];
      if (length === '20-48 Staggered')
        length = ROPE_LENGTH['Medium Wood'][size][numOfPendants][index];
        if (length === '20-72 Staggered')
          length = ROPE_LENGTH['Long Wood'][size][numOfPendants][index];
        if (length === '36-120 Staggered')
          length = ROPE_LENGTH['36-120 Wood'][size][numOfPendants][index];
        if (length === '36-180 Staggered')
          length = ROPE_LENGTH['36-180 Wood'][size][numOfPendants][index];
          if (length === '20-72 Step')
          length = ROPE_LENGTH['Step Long Modular'][size][numOfPendants][index];
          if (length === '20-72 Diagonal Step')
          length = ROPE_LENGTH['Diagonal Step Long Modular'][size][numOfPendants][index];
          if (length === '20-72 Diamond')
          length = ROPE_LENGTH['Diamond Long Modular'][size][numOfPendants][index];
  }

if(Model['Cord Type'] == 'Chain' && Model['Product'] !== 'Swag Chandelier') {
  if(unit == 'Feet'){
    unit = "Inches"
    length = length * 12 - 3;
  }
  else {
    if (length > 3){
      length -= 3;
    }
    
  }
}
  const lengthInches = unit === 'Inches' ? length : length * 12;
  PENDANTS[index].ropeLength = lengthInches;

  const config = cordType + length;
  if (
    !PENDANTS[index].nodes['Rope'].config ||
    config !== PENDANTS[index].nodes['Rope'].config
  ) {

    PENDANTS[index].nodes['Rope'].config = config;
    let ropeName = ``;
    if (Model['Cord Type'] == 'Chain'){
      ropeName = `Straight Rope ${cordType} ${Model['Chain Size']}`;
    }
    else  {
      ropeName = `Straight Rope ${cordType}`;
    }
  
    PENDANTS[index].resetNode('Rope');
    await PENDANTS[index].createNode(
      NODE_IDS[ropeName],
      'Rope',
      'Straight Rope'
    );
  
    const ropelength = await adjustRopeLength(
      index,
      length,
      unit,
      numOfPendants
    );
    return Promise.resolve(ropelength);
  } else {
    setVisibility(
      api.scene.find(PENDANTS[index].nodes['Rope'].id),
      true,
      'all'
    );
    return Promise.resolve();
  }
}

async function updateSocketStemNode(index) {
  if (Model['Socket Stem'] && Model['Socket Stem'] !== 'None') {
    const stemLength = Model['Socket Stem'].match(/(\d+)/);
    const nodeName = 'Stem - ' + stemLength[0] + '"';
    const config = Model['Socket Stem'];
    if (!PENDANTS[index].nodes['SocketStem'].config  || PENDANTS[index].nodes['SocketStem'].config  !== config || !PENDANTS[index].nodes['SocketStem'].id) {
      PENDANTS[index].nodes['SocketStem'].config = config;
      PENDANTS[index].resetNode('SocketStem');
      return PENDANTS[index].createNode(
        NODE_IDS[nodeName],
        'SocketStem',
        'Stem',
      );
      // setNodeMaterial(
      //  PENDANTS[index].nodes['SocketStem'].id,
      //   PENDANTS[index].matLib[matName]
      // );

    }
    else{
      console.log(PENDANTS[index].nodes['SocketStem'].id);
      setVisibility(
        api.scene.find(PENDANTS[index].nodes['SocketStem'].id),
        true,
        'all'
      );

    }
  }
  else {
   PENDANTS[index].resetNode('SocketStem');
  }
return Promise.resolve();
}






function updateCurvedRopeNode(index) {
  if (Model['Product'].indexOf('Swag') !== -1) {
    //update
    const cordType = isInternal
      ? Model[`Cord Type - ${index + 1}`]
      : Model['Cord Type'];
    const ropeDistance =
      Model[ATTRNAME_NUMBER_PENDANTS] < 9
        ? 'S'
        : Model[ATTRNAME_NUMBER_PENDANTS] === 9
        ? 'M'
        : 'L';
    let curvedRopeName = `Rope_Curve_${cordType}_${ropeDistance}`;
    if(cordType === 'Chain') {
      if(ropeDistance == 'L'){
        curvedRopeName = `Rope_Curve_${cordType}_M`;
      }
      curvedRopeName += '_' + Model['Chain Size'];
    }
    const config = curvedRopeName;
    if (PENDANTS[index].nodes['CurvedRope'].config !== config) {
      // console.log('Inside curved rope', index, config);
      PENDANTS[index].nodes['CurvedRope'].config = config;
      PENDANTS[index].resetNode('CurvedRope');
      return PENDANTS[index].createNode(
        NODE_IDS[curvedRopeName],
        'CurvedRope',
        'Rope_Curve'
      );
    } else {
      setNodeVisibility(
        api.scene.find({
          from: PENDANTS[index].nodes['CurvedRope'].id,
          name: curvedRopeName,
        }),
        true
      );
      return Promise.resolve();
    }
  } else {
    //remove
    return PENDANTS[index].resetNode('CurvedRope', true);
  }
}
function updateCeilingHookNode(index) {
  if (Model['Product'].indexOf('Swag') !== -1) {
    //update
    //TODO
    const value = isInternal
      ? Model[`Ceiling Hooks - ${index + 1}`]
      : Model['Ceiling Hooks'];
    console.log(value + " is the hook choice");
    const cordType = Model[`Cord Type - ${index + 1}`] || Model['Cord Type'];
    const [color, type] = value.split(' ');
    let ceilingHookName = 'Hook_silicone';
      if (value.includes('Thick'))
      {
        ceilingHookName = 'Hook_thick';
      }
      if(value.includes('Hook')){
        ceilingHookName = 'Hook_curved';
      }
      if(ceilingHookName == 'Hook_silicone' && Model['Cord Type'] == 'Thick'){
        ceilingHookName = 'Hook_curved';
      }
    if (
      !PENDANTS[index].nodes['CeilingHook'].config ||
      PENDANTS[index].nodes['CeilingHook'].config !== ceilingHookName
    ) {
       console.log('Inside ceiling hook', index, ceilingHookName);
      PENDANTS[index].nodes['CeilingHook'].config = ceilingHookName;

      PENDANTS[index].resetNode('CeilingHook');
      return PENDANTS[index]
        .createNode(NODE_IDS[ceilingHookName], 'CeilingHook', 'Hooks')
        .then(() => {
          setNodeMaterial(
            api.scene.find({
              from: PENDANTS[index].nodes['CeilingHook'].id,
              name: ceilingHookName,
            }),
            PENDANTS[index].matLib['M_Hook']
          );
          console.log('finished material');
        });
    } else {
      setNodeVisibility(
        api.scene.find({
          from: PENDANTS[index].nodes['CeilingHook'].id,
          name: ceilingHookName,
        }),
        true
      );
      return Promise.resolve();
    }
  } else {
    //remove
    return PENDANTS[index].resetNode('CeilingHook', true);
  }
}
function updateBottomGripNode(index) {
  const cordType = isInternal
    ? Model[`Cord Type - ${index + 1}`]
    : Model['Cord Type'];
  let bottomGripName = cordType === 'Thick' ? 'Rope_tie' : 'Grip';
  if(cordType === 'Chain'){
    bottomGripName = 'Chain_grip';
  }
  if (PENDANTS[index].nodes['BottomGrip'].config !== bottomGripName) {
    //update
    // console.log('Inside bottom grip', index, bottomGripName);
    PENDANTS[index].nodes['BottomGrip'].config = bottomGripName;
    PENDANTS[index].resetNode('BottomGrip');
    return PENDANTS[index].createNode(
      NODE_IDS[bottomGripName],
      'BottomGrip',
      'Grips'
    );
  } else if (Model['Product'] == 'Flush Mount' || Model['Product'].indexOf('Wall Sconce') !== -1  ||  (Model['Product'] === 'Single Pendant' && Model['Length(Feet)'] == '1' && Model['Socket Stem'].indexOf('7') !== -1)) {
    setNodeVisibility(
      api.scene.find({
        from: PENDANTS[index].nodes['BottomGrip'].id,
        name: bottomGripName,
      }),
      false
    );
    return Promise.resolve();
  }else {
    setNodeVisibility(
      api.scene.find({
        from: PENDANTS[index].nodes['BottomGrip'].id,
        name: bottomGripName,
      }),
      true
    );
    return Promise.resolve();
  }
}
function updateSocketNode(index) {
  const value = isInternal ? Model[`Socket - ${index + 1}`] : Model['Socket'];
  const type = SOCKET_TYPES.find(function(name) {
    return value.includes(name);
  });
  const socketName = `Socket_${type}`;
  if (PENDANTS[index].nodes['Socket'].config !== type) {
    // console.log('Inside socket', index, type);
    PENDANTS[index].nodes['Socket'].config = type;
    PENDANTS[index].resetNode('Socket');
    return PENDANTS[index].createNode(NODE_IDS[socketName], 'Socket', 'Socket');
  } else {
    setNodeVisibility(
      api.scene.find({
        from: PENDANTS[index].nodes['Socket'].id,
        name: socketName,
      }),
      true
    );
    return Promise.resolve();
  }
}
function updateCageNode(index) {
  const optionOn =
    (isInternal
      ? Model[`Cage / Shade - ${index + 1}`]
      : Model['Cage / Shade']) === 'Cage';
  if (optionOn) {
    const value = isInternal ? Model[`Cage - ${index + 1}`] : Model['Cage'];
    const type = CAGE_TYPES.find(function(name) {
      return value.includes(name);
    });
    const cageName = `${type} Cage`;
    if (PENDANTS[index].nodes['Cage'].config !== type) {
      // console.log('Inside cage', index, type);
      PENDANTS[index].nodes['Cage'].config = type;
      PENDANTS[index].resetNode('Cage');
      return PENDANTS[index].createNode(NODE_IDS[cageName], 'Cage', 'Cages');
    }
    //update
    else {
      setNodeVisibility(
        api.scene.find({
          from: PENDANTS[index].nodes['Cage'].id,
          name: cageName,
        }),
        true
      );
      return Promise.resolve();
    }
  } else {
    //remove
    return PENDANTS[index].resetNode('Cage', true);
  }
}
function updateShadeNode(index) {

  const optionOn =
    (isInternal
      ? Model[`Cage / Shade - ${index + 1}`]
      : Model['Cage / Shade']) === 'Shade';
  if (optionOn) {

    var value = isInternal ? Model[`Shade - ${index + 1}`] : Model['Shade'];
    if (value.includes('Mix')) {
      
      const type = value.replace('Shade Mix', '').trim();
      //need to change bulb data to include full bulb name and remove type below to make work easily in future
          value =  SHADE_MIXES.ref[type][index];   
          console.log("SHADE MIX node value is " + value);   
    }
    const shape = SHADE_SHAPES.find(function(name) {
      return value.includes(name);
    });
    const shadeName =
      (value.includes('Glass')  && !value.includes('Neckless') ? 'Glass ' : '') +
      shape +
      (value.includes('Cage') ? ' Cage' : ' Shade');
      if(Model['Shade'].includes('Shade Mix')){
        PENDANTS[index].nodes['Shade'].config = value;
      }
    if (PENDANTS[index].nodes['Shade'].config !== shadeName ) {
       console.log('Inside shade', index, shadeName);
       if(!Model['Shade'].includes('Shade Mix')){
        PENDANTS[index].nodes['Shade'].config = shadeName;
      }
      PENDANTS[index].resetNode('Shade');
      return PENDANTS[index].createNode(NODE_IDS[shadeName], 'Shade', 'Shades');
    } else {
      if(shape.includes('Neckless')){
      const nodeNameTop = shape + ' Shade_Top';
      setNodeMaterial(
      api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: nodeNameTop,
      }),
      PENDANTS[index].matLib['M_Shade Cover']
    );
    
      let color = Model['Shade Cover'];
      const coverMatId = PENDANTS[index].matLib['M_Shade Cover']
      if(color == 'Black'  || color == 'White'){
        setMaterialReflection(coverMatId, .8,.2);
      }
      else {
        setMaterialReflection(coverMatId, .2,1);
      }
      setMaterialBaseColor(
        coverMatId,
        Colors[Model['Shade Cover']]
      );
    }
      setVisibility(PENDANTS[index].nodes['Shade'].id, true, 'all');
      return Promise.resolve();
    }
  } else {
    //remove
    return PENDANTS[index].resetNode('Shade', true);
  }
}
function updateShadeFitterNode(index) {
  const optionOn =
    (isInternal
      ? Model[`Cage / Shade - ${index + 1}`]
      : Model['Cage / Shade']) === 'Shade';
  const shade = isInternal ? Model[`Shade - ${index + 1}`] : Model['Shade'];
  let mTOptionOn = true;

  if ( Model['Socket'].indexOf('Modern Threaded') !== -1){
      mTOptionOn = false;
  }
  if (Model['Shade'] && ( Model['Shade'].includes('Neckless') || Model['Shade'].includes('Disk') || Model['Shade'].includes('Woven') || Model['Shade'].includes('Deep Dome')  )) {
    mTOptionOn = false;
  }
  if (optionOn && shade.indexOf('Cage') === -1 && shade.indexOf('Felt') === -1 && shade.indexOf('Drum') === -1 && shade.indexOf('Industrial') === -1 && shade.indexOf('" Dome') === -1 && mTOptionOn) {
    const shadeFitterName = 'Shade Fitter';
    if (!PENDANTS[index].nodes['ShadeFitter'].config) {
      PENDANTS[index].nodes['ShadeFitter'].config = optionOn;
      PENDANTS[index].resetNode('ShadeFitter');
      return PENDANTS[index]
        .createNode(NODE_IDS[shadeFitterName], 'ShadeFitter', 'Shade Fitters')
        .then(() => {
          const color =
            Model[`Shade Fitter - ${index + 1}`] || Model['Shade Fitter'];
          if (color === 'Antique') {
            setNodeMaterial(
              api.scene.find({
                from: PENDANTS[index].id,
                type: 'PolyMesh',
                name: 'Shade Fitter',
              }),
              PENDANTS[index].matLib['M_Shade_FitterA']
            );
          } else {
            setNodeMaterial(
              api.scene.find({
                from: PENDANTS[index].id,
                type: 'PolyMesh',
                name: 'Shade Fitter',
              }),
              PENDANTS[index].matLib['M_Shade_Fitter']
            );
          }
        });
    } else {
      setNodeVisibility(
        api.scene.find({
          from: PENDANTS[index].nodes['ShadeFitter'].id,
          name: 'Shade Fitter',
        }),
        true
      );
      return Promise.resolve();
    }
  } else {
    //remove
    
    return PENDANTS[index].resetNode('ShadeFitter', true);
  }
}
async function updateBulbNode(index) {
  let value = isInternal ? Model[`Bulb - ${index + 1}`] : Model['Bulb'];
  if (value === 'None') {
    if (value === PENDANTS[index].nodes['Bulb'].config)
      return Promise.resolve();
    PENDANTS[index].nodes['Bulb'].config = value;
    PENDANTS[index].resetNode('Bulb');
    await PENDANTS[index].createNode(NODE_IDS['Bulb_None'], 'Bulb', 'Bulbs');
    return Promise.resolve();
  }
  if (value.includes('Mix')) {

    const type = value.replace('Bulb Mix', '').trim();
    //need to change bulb data to include full bulb name and remove type below to make work easily in future
      if(value.includes('XL') || value.includes('-2700k')){
        value = BULB_MIXES.ref[type][index];
      }
      else{
        value = type + ' ' + BULB_MIXES.ref[type][index];
      }
  }
  let findsize = value.match(/\d"/);
  if(value.includes('XL') || value.includes('Uneven') ){
    findsize ='';
  }
  const shape = BULB_SHAPES.find(function(element) {
    return value.includes(element);
  });
  const bulbName = findsize ? findsize[0] + ' ' + shape : shape;
  const dipped = value.includes('Dipped');
  const bulbNodeName = 'Bulb_' + (dipped ? 'Dipped ' : '') + bulbName;
  const option = ['Spiral', 'Clear', 'Smoke','White'].find(function(element) {
    return value.includes(element);
  });
  const styleName =
    (['Antique', 'LED'].find(function(element) {
      return value.includes(element);
    }) || 'Incandescent') + (option ? '_' + option : '');
  const config = bulbNodeName + '_' + styleName;
  if (PENDANTS[index].nodes['Bulb'].config !== config) {
    // console.log(PENDANTS[index].nodes['Bulb'].config, config);
    if (
      PENDANTS[index].nodes['Bulb'].config &&
      PENDANTS[index].nodes['Bulb'].config.includes(bulbNodeName)
    ) {
      PENDANTS[index].nodes['Bulb'].config = config;
      applyMaterialToBulbParts();
      showBulb();
      return Promise.resolve();
    } else {
      PENDANTS[index].nodes['Bulb'].config = config;
      PENDANTS[index].resetNode('Bulb');
      await PENDANTS[index].createNode(NODE_IDS[bulbNodeName], 'Bulb', 'Bulbs');
      applyMaterialToBulbParts();
      showBulb();
      return Promise.resolve();
    }
  } else {
    showBulb();
    applyMaterialToBulbParts();
    return Promise.resolve();
  }
  function applyMaterialToBulbParts() {

    const bulbNullId = api.scene.find({
      from: PENDANTS[index].id,
      name: 'Bulbs',
    });
    const baseId = api.scene.find({
      from: PENDANTS[index].id,
      name: 'Base',
    });
    const baseMatId = api.scene.find('Bulb Base');
    setNodeMaterial(baseId, baseMatId);
    const bulbId = api.scene.find({ from: bulbNullId, name: bulbNodeName });
    const innerPartMatId = value.includes('Smoke')
      ? api.scene.find('Part Inside Bulb Smoke')
      : api.scene.find('Part Inside Bulb');
    const filamentMatId = value.includes('Smoke')
      ? api.scene.find('Smoke Filament')
      : api.scene.find('Inside Light On Bulb');
    const wireMatId = api.scene.find('Inside Bulb Wires');
    api.scene.filter({ from: bulbId, shallow: true }).forEach(id => {
      setNodeMaterial(id, innerPartMatId);
    });
    api.scene.filter({ from: bulbId, name: 'Filament' }).forEach(id => {
      setNodeMaterial(id, filamentMatId);
    });
    api.scene.filter({ from: bulbId, name: 'Wire' }).forEach(id => {
      setNodeMaterial(id, wireMatId);
    });
    if (dipped) {
      setNodeMaterial(
        api.scene.find({ from: bulbNullId, name: 'Bulb_Dipped' }),
        PENDANTS[index].matLib['Copper Metal Half Half']
      );
      const color = value.split(' ')[1].trim();
      // console.log(color);
      if(color == 'Gold'){
        setMaterialBaseColor(
          PENDANTS[index].matLib['Copper Metal Half Half'],
          Colors['Dipped Gold']
        );
      }
      else {
        setMaterialBaseColor(
          PENDANTS[index].matLib['Copper Metal Half Half'],
          Colors[color]
        );
      }
      setNodeMaterial(bulbId, api.scene.find('Clear Glass Raugh'));
    } else if (value.includes('Antique')) {
      setNodeMaterial(bulbId, api.scene.find('Antique Glass Raugh'));
    } else if (value.includes('White')) {
      setNodeMaterial(bulbId, api.scene.find('White Glass'));
    } else if (value.includes('Clear')) {
      if (value.includes('2700k')){
        setNodeMaterial(bulbId, api.scene.find('Clear Glass Raugh-2700'));
      }
      else {
        setNodeMaterial(bulbId, api.scene.find('Clear Glass Raugh'));
      }
    } else if (value.includes('Smoke')) {
        if (value.includes('Uneven')){
          setNodeMaterial(bulbId, api.scene.find('Smoke Glass Raugh Uneven'));
        }
        else{
          setNodeMaterial(bulbId, api.scene.find('Smoke Glass Raugh'));
        }
      //api.scene.set({id:api.scene.find('Smoke Glass Raugh'), plug:'Material', property:'opacityFactor'}, ".8");
      //console.log(api.scene.get({id:api.scene.find('Smoke Glass Raugh'), plug:'Material', property:'opacityFactor'}));
    } else {
          if (value.includes('Uneven')){
            setNodeMaterial(bulbId, api.scene.find('XL Amber Glass Raugh Uneven'));
          }
         else if (value.includes('XL') ) {
            setNodeMaterial(bulbId, api.scene.find('XL Amber Glass Raugh'));
         }
         else if (value.includes('Geoglobe') ) {
            setNodeMaterial(bulbId, api.scene.find('XL Amber Glass Raugh Geo'));
         }
        else {
          setNodeMaterial(bulbId, api.scene.find('XL Amber Glass Raugh'));
        }
    }
  }
  function showBulb() {
    const bulbId = api.scene.find({
      from: PENDANTS[index].id,
      name: bulbNodeName,
    });
    const baseId = api.scene.find({
      from: PENDANTS[index].id,
      name: 'Base',
    });
    const componentsId = api.scene.find({ from: bulbId, name: styleName });
    setVisibility(bulbId, false, 'all');
    setNodeVisibility(bulbId, true);
    setVisibility(bulbId, true, styleName);
    setVisibility(componentsId, true, 'all');
    setNodeVisibility(baseId, true);
    if (dipped)
      setNodeVisibility(
        api.scene.find({
          from: PENDANTS[index].nodes['Bulb'].id,
          name: 'Bulb_Dipped',
        }),
        true
      );
      if(Model['Product'] == 'Torch Wall Sconce'){
        api.scene.setAll(api.scene.filter({from: PENDANTS[index].nodes['Bulb'].id, name: 'B*', plug: 'Transform', property: 'rotation'}), [180, 0, 0]);
      }
      else {
        const curRotation = getRotation(api.scene.find({
          from: PENDANTS[index].nodes['Bulb'].id,
          name: 'Bulbs_Sockets',
        }));
        if(curRotation['x'] == 180) {
          api.scene.setAll(api.scene.filter({from: PENDANTS[index].nodes['Bulb'].id, name: 'B*', plug: 'Transform', property: 'rotation'}), [0, 0, 0]);
        }
      }
  }
}

//////////////////////
async function adjustRopeLength(index, length, unit, numOfPendants) {
  /**
   *
   * @param {number} numOfCopies Number of copies to make
   * @param {number} length Number of length without unit
   * @param {string} unit Unit: Feet/Inches
   * @param {number} index index in Pendant
   */
     function cloneRopeExtensions(numOfCopies, length, unit, index) {
    return new Promise(function(resolve, reject) {
      const ropeModelNullId = api.scene.find({
        from: PENDANTS[index].id,
        name: `Straight Rope ${length} ${unit}`,
      });
      if (numOfCopies === 1) return resolve();
      if (numOfCopies === 0 ){
        if (Model['Cord Type'] == 'Chain'){
           return resolve(
            api.sceneGraph.deleteNode(
              api.scene.find({
                from: ropeModelNullId,
                name: 'Rope',
              })
            ), api.sceneGraph.deleteNode(
               api.scene.find({
                from: ropeModelNullId,
                name: 'Wire',
              })
            ));
            
    
        }
      
          return resolve(
            api.sceneGraph.deleteNode(
              api.scene.find({
                from: ropeModelNullId,
                name: 'Rope',
              })
            )
          );
        }

      
        let promises = [];
        for (let i = 1; i < numOfCopies; i++) {
          const name = `Rope_${index} ${i}`;
          promises.push(
            api.scene
              .addNode({
                name: name,
                parent: ropeModelNullId,
                type: 'PolyMesh',
                plugs: {
                  PolyMesh: [
                    [
                      'Reference',
                      {
                        source: api.scene.find({
                          from: ropeModelNullId,
                          name: `Rope`,
                        }),
                      },
                    ],
                  ],
                },
              })
              .then(function(id) {
                PENDANTS[index].nodes['Rope'].nodeMap[name] = id;
                const translation = api.scene.get({
                  id: id,
                  plug: 'Transform',
                  property: 'translation',
                });
               
                   const yDiff = (unit === 'Inches' ? 0.05 : 0.2 * length) * i;
              
                   setTranslation(id, [0, -yDiff, 0], translation);
                if(length == 3 && Model['Cord Type'] == 'Chain' && Model['Chain Size'] == '4 Inch' && i % 2 !== 0 ) {
                  setRotation(id, [0, 90, 0]);
                  //setTranslation(id, [0, .01, 0], translation);
                }
                return id;
              })
          );

          
if (Model['Cord Type'] == 'Chain'){
          const name = `Wire_${index} ${i}`;
          promises.push(
            api.scene
              .addNode({
                name: name,
                parent: ropeModelNullId,
                type: 'PolyMesh',
                plugs: {
                  PolyMesh: [
                    [
                      'Reference',
                      {
                        source: api.scene.find({
                          from: ropeModelNullId,
                          name: `Wire`,
                        }),
                      },
                    ],
                  ],
                },
              })
              .then(function(id) {
                PENDANTS[index].nodes['Rope'].nodeMap[name] = id;
                const translation = api.scene.get({
                  id: id,
                  plug: 'Transform',
                  property: 'translation',
                });
                
                   const yDiff = (unit === 'Inches' ? 0.05 : 0.2 * length) * i;
                  
                   setTranslation(id, [0, -yDiff, 0], translation);

                  
                  api.scene.set({id: id, plug: 'Transform', property: 'localRotatePivot'}, [0, -.0415, 0]);
                if(length == 3 && Model['Cord Type'] == 'Chain' && Model['Chain Size'] == '4 Inch' && i % 2 !== 0 ) {
                  setRotation(id, [0, -90, 180]);
                  //setTranslation(id, [0, .01, 0], translation);
                }
                return id;
              })
          );


            }











          
        
        Promise.all(promises).then(resolve);
      }
    });
  }

  return new Promise(async function(resolve, reject) {
    let clonePromises = [];

    const numOf2FeetRopes =
      unit === 'Inches' ? Math.floor(length / 24) : Math.floor(length / 2);
    const numOf1FeetRopes =
      unit === 'Inches'
        ? Math.floor((length % 24) / 12)
        : Math.floor(length % 2);
    const numOf3InchesRopes =
      unit === 'Inches' ? Math.ceil((length % 12) / 3) : 0;

    clonePromises = [
      cloneRopeExtensions(numOf2FeetRopes, 2, 'Feet', index, 0),
      cloneRopeExtensions(numOf1FeetRopes, 1, 'Feet', index, numOf2FeetRopes),
      cloneRopeExtensions(
        numOf3InchesRopes,
        3,
        'Inches',
        index,
        numOf2FeetRopes + numOf1FeetRopes
      ),
    ];
    await Promise.all(clonePromises);
    //Connect all ref rope clones
    const ropeId1F = api.scene.find({
      from: PENDANTS[index].id,
      name: 'Straight Rope 1 Feet',
    });
    const ropeId3I = api.scene.find({
      from: PENDANTS[index].id,
      name: 'Straight Rope 3 Inches',
    });

    let y = 0.4 * numOf2FeetRopes;
    if(Model['Cord Type']=='Chain'&& Model['Chain Size'] == '4 Inch')
    {
      if(numOf2FeetRopes != 0){
       y -= .011;
      }
    }
    setTranslation(ropeId1F, [0, -y, 0]);
    
    y += 0.2 * numOf1FeetRopes;
    if(Model['Cord Type']=='Chain' && Model['Chain Size'] == '4 Inch')
    {
      if(numOf1FeetRopes != 0){
        y -= .005;
      }
    }
    setTranslation(ropeId3I, [0, -y, 0]);
    y += 0.05 * numOf3InchesRopes;

    // Seperate this to increase gap between chain and grip by chain size
    if(Model['Cord Type']=='Chain')
    {
      y += .034;
      if (Model['Chain Size'] === '1 Inch'){
        y-= .01;
      }
    }
  


    setTranslation(
      api.scene.find({
        from: api.scene.find({
          from: PENDANTS[index].id,
          name: 'Straight Rope',
        }),
        name: 'Rope_Bottom',
      }),
      [0, -y, 0]
    );
    resolve();
  });
}

function pendantAttach(index) {
  // console.log('Reattaching...', index);
  const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
  const cordType = isInternal
    ? Model[`Cord Type - ${index + 1}`]
    : Model['Cord Type'];
  const bulbId = PENDANTS[index].nodes['Bulb'].id;
  const bulb_Top = api.scene.find({ from: bulbId, name: 'Bulbs_Top' });

  let bulb_ShadeFitter = api.scene.find({
    from: bulbId,
    name: 'Bulbs_Shade Fitters',
  });
  const socketId = PENDANTS[index].nodes['Socket'].id;
  if(Model['Socket Stem'] && Model['Socket Stem'] !== 'None'){
    var socketStemId = PENDANTS[index].nodes['SocketStem'].id;
  console.log("socketStemId defined");
}
  const tableTopId =  api.scene.find({ from: PENDANTS[0].nodes['Extras'].id, name: 'Bulb' });
  const socketBulbId = api.scene.find({ from: socketId, name: 'Socket_Bulbs' });
  const tableId = PENDANTS[0].nodes['Extras'].id;
  const socketTopId = api.scene.find({ from: socketId, name: 'Socket_Top' });
  const modernThreadedId = api.scene.find({ from: socketId, name: 'Socket_Shade' });
  if(modernThreadedId){
     bulb_ShadeFitter = api.scene.find({
      from: socketId,
      name: 'Socket_Shade Fitter',
    });
  }
  const bulb_Socket = api.scene.find({ from: bulbId, name: 'Bulbs_Sockets' });


  const cageId = PENDANTS[index].nodes['Cage'].id;
  const shadeId = PENDANTS[index].nodes['Shade'].id;
  if ( Model['Shade'] && Model['Shade'].indexOf("Neckless") !== -1){
    var shadeVtId = api.scene.find({
      from: shadeId,
      name: 'Very_Top',
    });
  }

  const ropeId = PENDANTS[index].nodes['Rope'].id;
  let ropeBottomId = api.scene.find({ from: ropeId, name: 'Rope_Bottom' });

  const curvedRopeId = PENDANTS[index].nodes['CurvedRope'].id;
  const curvedRopeTopId = api.scene.find({
    from: curvedRopeId,
    name: 'Rope_Top',
  });
  const shadeFitterId = PENDANTS[index].nodes['ShadeFitter'].id;
  const topGripId = PENDANTS[index].nodes['TopGrip'].id;
  const bottomGripId = PENDANTS[index].nodes['BottomGrip'].id;






  const gripBottomId = api.scene.find({
    from: bottomGripId,
    name: 'Grip_Bottom',
  });
  if (Model['Product'] == 'Flush Mount') {
      ropeBottomId = getBaseAttachPoint(index)
    }
    if (Model['Product'] == 'Hinge Wall Sconce') {
      ropeBottomId = api.scene.find('Hinge_Socket_Null');
    }
    if (Model['Product'] == 'Torch Wall Sconce') {
      ropeBottomId = api.scene.find('Torch_Socket_Null');
    }

  const ceilingHookId = PENDANTS[index].nodes['CeilingHook'].id;
  const attachPointId = getBaseAttachPoint(index);
  const straightRopeTopId = api.scene.find({
    from: PENDANTS[index].nodes['Rope'].id,
    name: 'Rope_Top',
  });
  
  const gripTopBottomID = api.scene.find({ from: topGripId, name: 'Grip_Bottom' });
  const gripTopId = api.scene.find({ from: topGripId, name: 'Grip_Top' });
  api.scene.attachModel(attachPointId, topGripId, gripTopId, false);
  if (cordType === 'Chain'){
    api.scene.attachModel(gripTopBottomID, ropeId, straightRopeTopId, false);
  } else{
    api.scene.attachModel(attachPointId, ropeId, straightRopeTopId, false);
  }
  

  let ropeEndId = ropeBottomId;
  if ( Model['Product'] == 'Flush Mount'){
    ropeEndId = socketBulbId;
  }
  if (Model['Product'].indexOf('Cluster') !== -1) {
    let chainRotation = 0;
    if ( cordType == 'Chain' && Model['Chain Size'] ==='4 Inch'){
      let chainLength = PENDANTS[index].nodes['Rope'].config;
      chainLength = chainLength.replace('Chain','');
      chainLength = chainLength % 12;
      chainLength = Math.ceil(chainLength / 3);
      if (chainLength == 1 || chainLength == 3){
        chainRotation = 90;
      }
     }




    const type = Model['Product'].split('-')[1].trim();
    const rotationDiff = calculateRotation(
      type,
      index,
      numOfPendants,
      PENDANTS[index].ropeLength
    );
    if (type === 'Grape') {
      const grapeRopeNullId = api.scene.find(
        `Grape_Rope_Start_${numOfPendants}_${index}`
      );
      //Rotate straight rope
      let x, y;
      let plateR, plateHolesAngle;
      const isThick = isInternal
        ? Model[`Cord Type - ${index + 1}`] === 'Thick'
        : Model['Cord Type'] === 'Thick';
      let ringR =
        numOfPendants > 10
          ? 0.75
          : numOfPendants < 6
          ? isThick
            ? 0.65
            : 0.35
          : 0.55;
      if (numOfPendants === 19) {
        if (index < 12) {
          plateR = 1.75;
          plateHolesAngle = ((2 * Math.PI) / 12) * index;
        } else {
          plateR = 1.0;
          plateHolesAngle = ((2 * Math.PI) / 7) * (index - 12);
        }
      } else {
        plateR = 1.5;
        plateHolesAngle = ((2 * Math.PI) / numOfPendants) * index;
      }
      x =
        -(Math.asin((plateR - ringR) / PENDANTS[index].ropeLength) / Math.PI) *
        180 *
        1.05;
      y = -(plateHolesAngle * 180) / Math.PI;
      setRotation(ropeId, [x, y, 0]);
      setRotation(topGripId, [x, y, 0]);
      //Attach grape to bottom of straight rope
      api.scene.attachModel(
        ropeBottomId,
        grapeRopeNullId,
        grapeRopeNullId,
        false
      );
      if (isThick) {
        ringR = numOfPendants > 10 ? 1.5 : numOfPendants < 6 ? 0.7 : 0.9;
        x =
          -(
            Math.asin(
              (plateR -
                (numOfPendants === 19 && index > 11 ? ringR - 0.3 : ringR)) /
                PENDANTS[index].ropeLength
            ) / Math.PI
          ) *
          180 *
          1.05;
        y = -(plateHolesAngle * 180) / Math.PI;
        setRotation(ropeId, [x, y, 0]);
      }
      ropeEndId = api.scene.find({
        from: grapeRopeNullId,
        name: `Grape_Rope_End_${numOfPendants}_${index}`,
      });
    } else {
      //Rotate rope
      setRotation(ropeId, [-rotationDiff.x, rotationDiff.y, 0]);
      setRotation(topGripId, [-rotationDiff.x, rotationDiff.y, 0]);
      setRotation(bottomGripId, [-rotationDiff.x, rotationDiff.y - chainRotation, 0]);
    }
    setRotation(socketId, [-rotationDiff.x, rotationDiff.y, 0]);
    if(Model['Socket Stem'] && Model['Socket Stem'] !== 'None'){
    setRotation(socketStemId, [-rotationDiff.x, rotationDiff.y, 0]);
  }
    setRotation(bulbId, [-rotationDiff.x, rotationDiff.y, 0]);
    setRotation(bottomGripId, [-rotationDiff.x, rotationDiff.y - chainRotation, 0]);
  } else {
    setRotation(ropeId, [0, 0, 0]);
    setRotation(socketId, [0, 0, 0]);
    setRotation(bulbId, [0, 0, 0]);
    setRotation(bottomGripId, [0, 0, 0]);
    //Rotate bottom grip to line up with chain links
    if ( cordType == 'Chain' && Model['Chain Size'] ==='4 Inch'){
      let chainLength = PENDANTS[index].nodes['Rope'].config;
      chainLength = chainLength.replace('Chain','');
      chainLength = chainLength % 12;
      chainLength = Math.ceil(chainLength / 3);
      if (chainLength == 1 || chainLength == 3){
        setRotation(bottomGripId, [0, -90, 0]);
      }
     }
    setRotation(topGripId, [0, 0, 0]);
    if(Model['Socket Stem'] && Model['Socket Stem'] !== 'None'){
      setRotation(socketStemId, [0, 0, 0]);
    }
  }
  api.scene.attachModel(attachPointId, topGripId, gripTopId, false);
  if (Model['Product'].indexOf('Swag') !== -1) {
    //Deal with pendant rotation & hook
    const yRotation = 360 / numOfPendants;
    let rotation;
    if (numOfPendants === 19) {
      //This plate has 2 rounds, this INDEXMAP reindexs holes to make attached
      //ropes spread evenly
      rotation = INDEXMAP[index] * yRotation;
     }  else if (numOfPendants === 2) {
      //This plate has 2 rounds, this INDEXMAP reindexs holes to make attached
      //ropes spread evenly
      rotation = index * yRotation-90;
    } else {
      rotation = index * yRotation;
    }
    setRotation(curvedRopeId, [0, -rotation, 0]);
    setRotation(topGripId, [0, -rotation, 0]);
    setRotation(ceilingHookId, [0, -rotation, 0]);
    setRotation(ropeId, [0, -rotation-90, 0]);
    setRotation(bottomGripId, [0, -rotation-90, 0]);
    if(cordType === 'Chain'){
      api.scene.attachModel(gripTopBottomID, curvedRopeId, curvedRopeTopId, false);
    }
    else{
    api.scene.attachModel(attachPointId, curvedRopeId, curvedRopeTopId, false);
    }
    const hook_Rope = api.scene.find({
      from: ceilingHookId,
      name: 'Hook_Rope',
    });
    const rope_Hook = api.scene.find({
      from: curvedRopeId,
      name: 'Rope_Hook',
    });
    const curvedRopeBottom = api.scene.find({
      from: curvedRopeId,
      name: 'Rope_Bottom',
    });
    api.scene.attachModel(rope_Hook, ceilingHookId, hook_Rope, false);
    api.scene.attachModel(curvedRopeBottom, ropeId, straightRopeTopId, false);
  } else if (Model['Product'].indexOf('Wrap Chandelier') !== -1) {
    const wrapRopesNull = api.scene.find('Wrap Ropes');
    const nulls =
      cordType === 'Thick'
        ? api.scene.find({
            from: wrapRopesNull,
            name: `Thick_Rope_Ends_${numOfPendants}`,
          })
        : api.scene.find({ from: wrapRopesNull, name: 'Rope_Ends' });
    ropeEndId = api.scene.find({
      from: nulls,
      name: `Rope_Bottom_${index}`,
    });
  } else if (Model['Product'].indexOf('Beam Wrap') !== -1) {
    const wrapRopesNull = api.scene.find('Beam Wrap Ropes');
    const nulls =
      cordType === 'Thick'
        ? api.scene.find({
            from: wrapRopesNull,
            name: `Thick_Rope_Ends_${numOfPendants}`,
          })
        : api.scene.find({ from: wrapRopesNull, name: `Rope_Ends_${numOfPendants}`});
    ropeEndId = api.scene.find({
      from: nulls,
      name: `Rope_Bottom_${index}`,
    });
}

  

  if ( Model['Product'] == 'Flush Mount'){
    api.scene.attachModel(attachPointId , socketId, socketTopId, false);
    //api.scene.attachModel(ropeEndId, bulbId, bulb_Top, false);
    api.scene.attachModel(socketBulbId, bulbId, bulb_Top, false);
  }

  if (shadeId) {
    if(Model['Shade'].includes('Neckless') || Model['Shade'].includes('Woven')){
      api.scene.attachModel(
        socketTopId,
        shadeId,
        api.scene.find({ from: shadeId, name: 'Shades_Top' }),
        false
      );
    }
    else if(modernThreadedId){
      api.scene.attachModel(
        modernThreadedId,
        shadeId,
        api.scene.find({ from: shadeId, name: 'Shades_Top' }),
        false
      );
    }
    else {
      api.scene.attachModel(
        ropeEndId,
        shadeId,
        api.scene.find({ from: shadeId, name: 'Shades_Top' }),
        false
      );
    }
  }
  if (cageId) {
  
    api.scene.attachModel(
      socketBulbId,
      cageId,
      api.scene.find({ from: cageId, name: 'Cages_Top' }),
      false
    );
  }
  if ( Model['Product'] !== 'Flush Mount'){
    if( Model['Product'].indexOf('Wall Sconce') !== -1){
      api.scene.attachModel(ropeEndId, socketId, socketTopId, false);
    }
    else {
      api.scene.attachModel(ropeEndId, socketId, socketTopId, false);
    }

    api.scene.attachModel(socketBulbId, bulbId, bulb_Top, false);

  }

  if (shadeFitterId) {
    api.scene.attachModel(
      bulb_ShadeFitter,
      shadeFitterId,
      api.scene.find({ from: shadeFitterId, name: 'Shade Fitters_Cage' }),
      false
    );
  }
  if (shadeId) {
    if(Model['Shade'].includes('Neckless') ||  Model['Shade'].includes('Woven')){
      api.scene.attachModel(
        socketTopId,
        shadeId,
        api.scene.find({ from: shadeId, name: 'Shades_Top' }),
        false
      );
    }
    else if(modernThreadedId){
      api.scene.attachModel(
        modernThreadedId,
        shadeId,
        api.scene.find({ from: shadeId, name: 'Shades_Top' }),
        false
      );
    }
    else {
      api.scene.attachModel(
        ropeEndId,
        shadeId,
        api.scene.find({ from: shadeId, name: 'Shades_Top' }),
        false
      );
    }
  }
  if (cageId) {
    api.scene.attachModel(
      socketBulbId,
      cageId,
      api.scene.find({ from: cageId, name: 'Cages_Top' }),
      false
    );
  }
  if(Model['Socket Stem'] && Model['Socket Stem'] !== 'None'){

  const socketStemBotId = api.scene.find({
    from: socketStemId,
    name: 'StemBot',
  });
  const socketStemTopId = api.scene.find({
    from: socketStemId,
    name: 'StemTop',
  });

if (Model['Shade'] && Model['Shade'].indexOf("Neckless") !== -1){
  api.scene.attachModel(shadeVtId,socketStemId,socketStemBotId , false);
}
else{
    api.scene.attachModel(socketTopId,socketStemId,socketStemBotId , false);
  }
    api.scene.attachModel(socketStemTopId, bottomGripId, gripBottomId, false);
  }
  else if ( Model['Product'] !== 'Flush Mount'){
    api.scene.attachModel(socketTopId, bottomGripId, gripBottomId, false);
  }

 // if ( index == 0){
    //api.scene.attachModel(socketBulbId, tableId, tableTopId, false);
   // let tablePosition = api.scene.get({name:'Dining Table', plug:'Transform', property:'translation'});
   // console.log(tablePosition);
   // api.scene.set({name:'Dining Table', plug:'Transform', property:'translation'}, [0,0,tablePosition[2]]);

 //}
//  if ( index == 0){
//  let nodeId = api.scene.find({ name: PENDANTS[0].id });
//  let boundingBox = api.scene.getNodeBoundingBox(nodeId);
//  console.log("index is " + index);
// console.log(boundingBox);
// api.scene.set({name:'Dining Table', plug:'Transform', property:'translation'}, [0,boundingBox.min.y,0]);
// let tablePosition = api.scene.get({name:'Dining Table', plug:'Transform', property:'translation'});
// console.log(tablePosition);
//  }



}
function calculateRotation(type, index, numOfPendants, ropeLength) {
  let x,
    y,
    z = 0;
  if (type === 'Grape') {
    x = VECTORS[type][numOfPendants][index].x;
    y = VECTORS[type][numOfPendants][index].y;
    return { x, y, z };
  }
  let plateR, vector_Hole, plateHolesAngle;
  if (numOfPendants === 19) {
    if (index < 12) {
      plateR = 1.75;
      plateHolesAngle = ((2 * Math.PI) / 12) * index;
    } else {
      plateR = 1.0;
      plateHolesAngle = ((2 * Math.PI) / 7) * (index - 12);
    }
  } else {
    plateR = 1.5;
    plateHolesAngle = ((2 * Math.PI) / numOfPendants) * index;
  }
  vector_Hole = {
    x: plateR * Math.sin(plateHolesAngle),
    y: plateR * Math.cos(plateHolesAngle),
  };
  let vector_Rope = { x: 0, y: 0 };
  if (type === 'Even' || type === 'Staggered') {
    vector_Rope.x = VECTORS[type][numOfPendants][index].x - vector_Hole.x;
    vector_Rope.y = VECTORS[type][numOfPendants][index].y - vector_Hole.y;
    if (type === 'Staggered') ropeLength += 10;
    x =
      -(
        Math.asin(
          Math.sqrt(Math.pow(vector_Rope.x, 2) + Math.pow(vector_Rope.y, 2)) /
            ropeLength
        ) / Math.PI
      ) * 180;
    // console.log('X = ' + x);
    y = -(Math.atan2(vector_Rope.x, vector_Rope.y) / Math.PI) * 180;
    // console.log('Y = ' + y);
  } else {
    console.warn('Unknown type of cluster product');
  }
  return { x, y, z };
}
function getBaseAttachPoint(index) {
  let ropeBase;
  const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
  if (Model['Product'].indexOf('Wood') !== -1 ) {
    //Wood Chandelier
    const productSize = Model['Product']
      .split('-')[1]
      .trim()
      .replace(/["]+/g, '');
    ropeBase = api.scene.find({
      from: { name: productSize },
      name: '' + numOfPendants,
    });
  } else if ( Model['Product'].indexOf('Metal Chandelier') !== -1 ) {
    //Metal Chandelier
    const productSize = 'Metal - ' + Model['Product']
      .split('-')[1]
      .trim()
      .replace(/["]+/g, '');
    ropeBase = api.scene.find({
      from: { name: productSize },
      name: '' + numOfPendants,
    });

  } else if ( Model['Product'].indexOf('Modular Chandelier') !== -1 ) {
    //Modular Chandelier
    const productSize = 'Modular - ' + Model['Product']
      .split('-')[1]
      .trim()
      .replace(/["]+/g, '');
    ropeBase = api.scene.find({
      from: { name: productSize },
      name: '' + numOfPendants,
    });

  } else if (Model['Product'].indexOf('Single') !== -1 || Model['Product'].indexOf('Beam Wrap') !== -1) {
    ropeBase = api.scene.find('Ceiling Plate_1 Hole');
    return api.scene.find({
      from: { id: ropeBase },
      name: `Root_0`,
    });
  }
  else if ((Model['Product'].indexOf('Swag') || Model['Product'].indexOf('Cluster')) && Model['Number of Pendants'] >= 9  && (Model['Cord Type'] == 'Thick' || Model['Cord Type'] == 'Chain') ){
    ropeBase = api.scene.find(`12Ceiling Plate_${numOfPendants} Hole`);
  } 
  else if (Model['Ceiling Plate'] && Model['Ceiling Plate'].indexOf('Wood') !== -1){
    ropeBase = api.scene.find(`WoodCeiling Plate_${numOfPendants} Hole`);
  }
  else {
    ropeBase = api.scene.find(`Ceiling Plate_${numOfPendants} Hole`);
  }
  //console.log("Attaching root " + index);
  return api.scene.find({
    from: { id: ropeBase },
    name: `Root_${index}`,
  });

}
export {
  PENDANTS,
  updatePendantNodes,
  updateTopGripNode,
  updateRopeNode,
  updateCurvedRopeNode,
  updateCeilingHookNode,
  updateCageNode,
  updateShadeNode,
  updateShadeFitterNode,
  updateSocketNode,
  updateBottomGripNode,
  updateBulbNode,
  pendantAttach,
  updateExtrasNode,
  updateSocketStemNode,
};
