import api from './helpers/claraplayer';
import urlParams from './helpers/urlParams';
import { Controllor } from './Controllor';
import { Model } from './Model';
import { View } from './View';
import { BULB_MIXES, SHADE_MIXES } from './metadata';
import { ATTRNAME_NUMBER_PENDANTS } from './constants';
import { onChangeStart, onChangeFinishing, onLoadingStep } from './helpers/callbacks';
const { isInternal } = urlParams;

async function changeHandler([attr, value]) {
  if (!Model[attr.name] || value !== Model[attr.name]) {
    onChangeStart();
    onLoadingStep("Loading Assets...");
    switch (attr.name) {
      case 'Product':
        Controllor.resetModel(value);
        Controllor.resetConfigurator(value);
        View.clearProductView();
        onLoadingStep("Rendering Product...");
        View.update['Product']().then(() => onChangeFinishing(attr.name));
        break;
      case 'Number of Pendants':
        if (value !== Model[attr.name] * 1) {
          BULB_MIXES.setRef(Model['Product'], value);
          SHADE_MIXES.setRef(Model['Product'], value);
          if (isInternal) {
            Controllor.updatePendantsModel(value);
          } else Model[ATTRNAME_NUMBER_PENDANTS] = value * 1;
          View.update['Number of Pendants']().then(() => onChangeFinishing(attr.name));
          Controllor.buildForm(value);
        }
        break;
      case 'Ceiling Plate':
      case 'Outgoing Wire':
      case 'Wood Stain':
      case 'Wood Type':
      case 'Hardware Finish':
      case 'Cluster Ring':
      case 'Cord Type':
      case 'Round Cord Color':
      case 'Twisted Cord Color':
      case 'Thick Cord Type':
      case 'Socket':
      case 'Cage / Shade':
      case 'Shade':
      case 'Shade Fitter':
      case 'Cage':
      case 'Bulb':
      case 'Ceiling Hooks':
      case 'Fixture Length':
      case 'Length(Feet)':
      case 'Length':
      case 'Extras':
      case 'Socket Stem':
        Controllor.handler(attr, value).then(() => onChangeFinishing(attr.name));
        break;
      default:
        switch (isInternal) {
          case /Round Cord Color - \d+$/.test(attr.name):
          case /Twisted Cord Color - \d+$/.test(attr.name):
          case /Thick Cord Type - \d+$/.test(attr.name):
          case /Cord Type - \d+$/.test(attr.name):
          case /Cage \/ Shade - \d+$/.test(attr.name):
          case /Socket - \d+$/.test(attr.name):
          case /Cage - \d+$/.test(attr.name):
          case /Shade - \d+$/.test(attr.name):
          case /Shade Fitter - \d+$/.test(attr.name):
          case /Bulb - \d+$/.test(attr.name):
          case /Ceiling Hooks - \d+$/.test(attr.name):
          case /Fixture Length - \d+$/.test(attr.name):
          case /Length\(Feet\) - \d+$/.test(attr.name):
          case /Length - \d+$/.test(attr.name):
            Controllor.handler(attr, value).then(() => onChangeFinishing(attr.name));
            break;
          default:
            break;
        }
        break;
    }
  }
  else {
    onChangeFinishing(attr.name);
  }
}

export { changeHandler };
