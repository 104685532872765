import { Model } from '../Model';
import api from './claraplayer';


  const PRICES = {
    Product: {
      'Wood Chandelier - 18" Square': 10,
      'Wood Chandelier - 18" Round': 10,
      'Wood Chandelier - 35" x 9"': 10,
      'Wood Chandelier - 35" x 12"': 10,
      'Wood Chandelier - 47" x 9"': 10,
      'Wood Chandelier - 47" x 12"': 15,
      'Wood Chandelier - 24" Round': 20,
      'Wood Chandelier - 24" Square': 20,
      'Wood Chandelier - 70" x 12"': 30,
      'Wood Chandelier - 35" x 18"': 30,
      'Wood Chandelier - 47" x 24"': 30,
      'Cluster Chandelier - Even': 3,
      'Cluster Chandelier - Grape': 3,
      'Cluster Chandelier - Staggered': 3,
      'Wrap Chandelier': 6,
      'Single Pendant': 1,
      'Swag Chandelier': 3,
      'Metal Chandelier - 12" Round': 15,
      'Metal Chandelier - 18" Round': 20,
      'Metal Chandelier - 18" Square': 20,
      'Metal Chandelier - 24" Round': 20,
      'Metal Chandelier - 24" Square': 20,
      'Metal Chandelier - 33" Round': 30,
      'Metal Chandelier - 36" Square': 35,
      'Metal Chandelier - 48" Square': 100,
      'Metal Chandelier - 35" x 12"': 20,
      'Metal Chandelier - 47" x 12"': 20,
      'Metal Chandelier - 70" x 12"': 30,
      'Metal Chandelier - 16" Round': 15,
      'Flush Mount': 2,
      'Beam Wrap - 4 Feet': 30,
      'Torch Wall Sconce': 3,
      'Hinge Wall Sconce': 3,
      'Modular Chandelier - 48" Square': 100,
      'Modular Chandelier - 36" x 96"': 50,
    },
    'Cord Type Per Foot': {
      Round: .1,
      Twisted: .1,
      Thick: .3,
      'Chain 1 Inch': .5,
      'Chain 4 Inch': .3,
    },
    'Shades and Cages': {
      'Glass Globe': 2.5,
      'Amber Glass Globe': 2.5,
      'Glass Cylinder': 2.5,
      'Glass Dome': 2.5,
      'Glass Cone': 2.5,
      'Glass Bell': 2.5,
      'Copper Cone': 1,
      'Antique Cone': 1,
      'Black Cone': 1,
      'Nickel Cone': 1,
      'Black Ruffle': 1,
      'Metal Net': 1,
      'Black Large Flat': 1,
      'Black Diamond': 1,
      'Brass Diamond': 1,
      'Copper Diamond': 1,
      'Chrome Diamond': 1,
      'Mint Diamond': 1,
      'White Diamond': 1,
      'Black Round': 1,
      'Black Hinge': 1,
      'Brass Hinge': 1,
      'Steel Round': 1,
      'Tube Cage': 1,
      'Flare Cage': 1,
      'Radio Cage': 1,
      'Brass Tube Cage':1,
      'White Cone':1,
      'Brass Cone':1,
      'Black 5" Drum':1,
      'Nickel 5" Drum':1,
      'Copper 5" Drum':1,
      'Brass 5" Drum':1,
      'White 5" Drum':1,
      'Black 12" Drum':1,
      'Nickel 12" Drum':1,
      'Copper 12" Drum':1,
      'Brass 12" Drum':1,
      'White 12" Drum':1,
      'Black 16" Industrial':1.5,
      'Nickel 16" Industrial':1.5,
      'Copper 16" Industrial':1.5,
      'Brass 16" Industrial':1.5,
      'White 16" Industrial':1.5,
      'Grey Felt Cone':.5,
      'Khaki Felt 10" Dome':1,
      'Grey Felt 20" Flat':1.5,
      'Grey Felt 16" Dome':1.5,
      'Black Small Flat':1,
      'Glass Small Flat':1,
      'Frosted Glass Cone':1.5,
      'Black 5" Dome':.5,
      'Nickel 5" Dome':.5,
      'Copper 5" Dome':.5,
      'Brass 5" Dome':.5,
      'White 5" Dome':.5,
      'Black 12" Dome':5,
      'Black 18" Dome':10,
      'Black 24" Dome':15,
      'Brass 18" Dome': 10,
      'Brass 24" Dome': 15,
      'White 18" Dome': 10,
      'White 24" Dome': 15,
      'Glass 10" Neckless':5,
      'White 10" Neckless':5,
      'Glass 12" Neckless':8,
      'White 12" Neckless':8,
      'Glass 14" Neckless':12,
      'White 14" Neckless':12,
      'Glass 16" Neckless':15,
      'White 16" Neckless':15,
      'Black 24" Disk':10,
      'Brass 24" Disk':10,
      'White 24" Disk':10,
      'Black 5" Disk':2,
      'Brass 5" Disk':2,
      'Black 8" Disk':4,
      'Brass 8" Disk':4,
      'White 8" Disk':4,
      'Black 12" Disk':6,
      'Brass 12" Disk':6,
      'Black Octagon Cage': 2,
      'Brass Octagon Cage': 2,
      'Black 20" Deep Dome': 15,
      'White 20" Deep Dome': 15,
      'Grey 20" Deep Dome': 15,
      'Bamboo 18" Woven Basket': 5,
      'Bamboo 18" Woven Round': 5,
      'Bamboo 24" Woven Basket': 8,
      'Bamboo 30" Woven Basket': 10,
      'Bamboo 36" Woven Basket': 20,
      'Bamboo 24" Woven Round': 8,
      'Glass 8" Neckless': 5,
      'White 8" Neckless': 5,
      'Black/Brass 24" Dome': 15,
      'White Neckless Shade Mix': 7,

    },
    Bulb: {
      'None':0,
      'Antique Edison 40w': 1,
      'Antique Edison 60w': 1,
      'Antique Victorian': 1,
      'Antique Radio': 1,
      'Antique 3" Spiral Globe': 1,
      'Antique 5" Globe': 1,
      'Antique 7" Tube': 1,
      'LED 7" Tube': 1,
      'LED Edison': 1,
      'LED Victorian': 1,
      'LED Radio': 1,
      'LED 3" Globe': 1,
      'LED 5" Globe': 1,
      'LED 3" Clear Globe': 1,
      'LED 3" Clear Globe -3500k': 1,
      'LED 5" Clear Globe': 1,
      'LED 5" Clear Globe -3500k': 1,
      'LED 5" Milk White Globe': 1,
      'LED Milk White Edison': 1,
      'LED Milk White 4" Tube': 1,
      'Clear A15': 1,
      'Clear 4" Tube': 1,
      'Clear 3" Globe': 1,
      'Clear 5" Globe': 1,
      'LED Diamond': 1,
      'Dipped Copper 5" LED Globe': 1,
      'Dipped Gold 5" LED Globe': 1,
      'Dipped Chrome 5" LED Globe': 1,
      'Antique Bulb Mix': 1,
      'LED Amber Bulb Mix': 1,
      'Incandescent Clear Bulb Mix': 1,
      'LED Smoke Edison': 1,
      'LED Smoke 7" Tube': 1,
      'LED Smoke 3" Globe': 1,
      'LED Smoke 5" Globe': 1,
      'Dipped Black 5" LED Globe': 1,
      'LED Smoke Uneven 5" Globe':1,
      'LED Uneven 5" Globe':1,
      'LED Amber XL Beehive':2,
      'LED Amber XL Uneven Pear':2,
      'LED Amber XL Pear':2,
      'LED Amber XL 11" Tube':2,
      'LED Amber XL 8" Globe':2,
      'LED Amber XL Oval':2,
      'LED Amber XL Turret':2,
      'LED Amber XL Teardrop':2,
      'LED Amber XL Mushroom':2,
      'LED Amber XL Uneven Bottle':2,
      'LED Amber XL Gourd':2,
      'LED Smoke XL Uneven Pear':2,
      'LED Smoke XL UFO':2,
      'LED Smoke XL Bubbles':2,
      'LED Smoke XL Bottle':2,
      'LED Smoke XL Lined Teardrop':2,
      'LED Smoke XL Antler':2,
      'LED Amber XL Bulb Mix':2,
      'LED Smoke XL Bulb Mix':2,
      'LED White Diamond':1,
      'LED Clear Edison':1,
      'LED Clear Edison -3500k':1,
      'LED White 2" Globe':1,
      'LED White 3" Globe':1,
      'LED 3" Clear Globe -2700k': 1,
      'LED 5" Clear Globe -2700k': 1,
      'LED Clear Edison -2700k':1,
      'LED 2" Clear Globe -2700k': 1,
      'LED Amber XL Antler': 2,
      'LED White XL 8" Globe': 2,
      'LED Clear XL 8" Globe -2700k': 2,
      'LED White Bulb Mix': 1,
      'LED Clear Globe Bulb Mix': 1.2,
      'LED Amber Globe Bulb Mix': 1.2,
      'LED White Globe Bulb Mix': 1.2,
    },
       Length: {
      '1': 1,
      '2': 2,
      '3': 3,
      '4': 4,
      '5': 5,
      '6': 6,
      '7': 7,
      '8': 8,
      '9': 9,
      '10': 10,
      '11': 11,
      '12': 12,
      '13': 13,
      '14': 14,
      '15': 15,
      '16': 16,
      '17': 17,
      '18': 18,
      '19': 19,
      '20': 20,
      '21': 2,
      '24': 2,
      '27': 2,
      '30': 3,
      '33': 3,
      '36': 3,
      '39': 3,
      '42': 3,
      '45': 4,
      '48': 4,
      '51': 4,
      '54': 4,
      '57': 5,
      '60': 5,
      '63': 5,
      '66': 5,
      '69': 6,
      '72': 6,
      '4-6 Feet Staggered': 5,
      '6 Feet': 6,
      '6-10 Feet Staggered': 8,
      '10 Feet': 10,
      '10-15 Feet Staggered': 12,
      '15 Feet': 15,
      '20-35 Staggered':2,
      '20-48 Staggered': 3,
      '20-72 Staggered': 4,
      '36-120 Staggered': 6,
      '36-180 Staggered': 8,
      'Staggered': 2,
      '84':7,
      '96':8,
      '108':9,
      '120':10,
      '180':11,
      '240':20,
    },
  };
///PDF related functions
function generateWeight() {
    var weight = 0;
    var nop = 1;
    var cordTypeWeight = 0;
    if(Model["Number of Pendants"]){
    nop = Model["Number of Pendants"];
  }

   

      for (let key in PRICES) {
        switch (key) {
          case 'Product':
            weight += PRICES[key][Model['Product']];
            break;
          case 'Cord Type Per Foot':
            var cordTypeKey = Model['Cord Type'];
            if (cordTypeKey == 'Chain')
            {
                cordTypeKey = 'Chain ' + Model['Chain Size'];

            }
                cordTypeWeight =  PRICES[key][cordTypeKey];

            break;
          case 'Shades and Cages':
            if (Model["Cage / Shade"] == 'Shade') {
                weight += PRICES[key][Model['Shade']] * nop;
            }
            else if (Model["Cage / Shade"] == 'Cage') {
                weight += PRICES[key][Model['Cage']] * nop;
            }
            break;
          case 'Bulb':
            weight += PRICES[key][Model['Bulb']] * nop;
            console.log(PRICES[key][Model['Bulb']] );
            console.log(nop);
            console.log(Model['Bulb']);
            break;
          case 'Length':

          var thisLength = Model["Length"] || Model["Length(Feet)"] || Model["Fixture Length"];


          if (thisLength ){
            weight += PRICES[key][thisLength] * nop * cordTypeWeight;
            }

            break;
            
          default:
            break;
        }
        }
   


  return weight;










}


export { generateWeight };
